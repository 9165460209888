/**
 * ASS
 */
// 案件種別
export const ENUM_MASTER_EVENT_TYPE = Object.freeze({
  VISIT: 1, // 訪問査定案件
  BRING: 2, // 持ち込み査定案件
  DELIVERY: 3, // 宅配査定案件
  CORPORATION: 4, // 法人査定案件
  SIMPLE: 5, // 簡易査定案件
  STORE: 6, // 店舗査定案件
});

// 査定種別(Assessment用)
export const ENUM_MASTER_ASSESSMENT_TYPE = Object.freeze({
  FIELD: 1, // 現場査定
  KEEP_THE_ITEM: 2, // 預かり査定
  DELIVERY: 3, // 宅配査定
  CORPORATION: 4, // 法人査定
  SIMPLE: 5, // 簡易査定
  STORE: 7, // 店舗査定
});

// 査定状況
export const ENUM_MASTER_ASSESSMENT_STATUSES = Object.freeze({
  UN_ASSESSED: 0, // 未査定
  ASSESSING: 1, // 査定中
  LACK_OF_ASSESSMENT_INFORMATION: 2, // 査定情報不足
  REQUIRED_KEEP_ASSESSMENT: 3, // 要預かり査定
  BUYABLE: 4, // 買取可能
  NON_BUYABLE: 5, // 買取不可
});

// 査定操作
export const ENUM_APPRAISAL_ITEM_STATUS_OPTION = Object.freeze({
  UNSELECTED: 'NOT_DETERMINED', // 未選択
  AVAILABLE_FOR_PURCHASE: 'BUYABLE', // 買取可能
  NOT_AVAILABLE_FOR_PURCHASE: 'UNBUYABLE', // 買取不可
  INSUFFICIENT_INFOMATION_TO_ASSESS: 'INSUFFICIENT_INFORMATION', // 査定する情報が不足
});

// 査定操作
export const ENUM_ASSESSMENT_OPTION = Object.freeze({
  UNSELECTED: 1, // 未選択
  AVAILABLE_FOR_PURCHASE: 2, // 買取可能
  NOT_AVAILABLE_FOR_PURCHASE: 3, // 買取不可
  CUSTODY_ASSESSMENT_REQUIRED: 4, // 預かり査定が必要
  INSUFFICIENT_INFOMATION_TO_ASSESS: 5, // 査定する情報が不足
});

// 案件対応状況
export const ENUM_MASTER_EVENT_STATUS = Object.freeze({
  UN_DEFINED: 0, // 未定義
  DOING: 2, // 対応中
  CANCELED: 3, // キャンセル
  DONE: 5, // 対応完了
});

// 性別
export const ENUM_GENDER = Object.freeze({
  WOMAN: { id: 2, name: '女' },
  MAN: { id: 1, name: '男' },
  NO_ANSWER: { id: 3, name: '無回答' },
  UNKNOWN: { id: 4, name: '不明' },
});

// 売買契約 or 預かり証契約
export const ENUM_CONTRACT_CLASSIFICATION = Object.freeze({
  SALES_CONTRACT: { id: 1, name: '売買契約' },
  KEEP_THE_ITEM_CONTRACT: { id: 2, name: '預かり証契約' },
});

// 契約種別
export const ENUM_MASTER_CONTRACT_TYPE = Object.freeze({
  SALES_CONTRACT: 1, // 現場査定売買契約
  KEEP_THE_ITEM_CONTRACT: 2, // 預かり査定売買契約
  DELIVERY: 3, // 宅配査定売買契約
  CORPORATION: 4, // 法人査定売買契約
  STORE: 5, // 店舗査定売買契約
  KEEP_THE_ITEM_CONTRACT_AT_STORE: 6, // 店舗預かり査定売買契約
});

// 作成日区分
export const ENUM_DATE_CLASSIFICATION = Object.freeze({
  NOTSPECIFIED: 1, // 指定しない
  TODAY: 2, // 本日の日付
  SPECIFIEDDATE: 3, // 日付で期間を指定
});

// 店舗種別
export const ENUM_STORE_TYPE = Object.freeze({
  NOTSPECIFIED: 0, // 指定しない
  STORE: 1, // 店舗
  EXIBITION: 2, // 催事
});

// 商材査定検索の並び順
export const ENUM_SORT_TYPE = Object.freeze({
  BY_ASSESSMENT_DATE: 1,
  BY_EVENT: 2,
});

/**
 * 契約状況
 */
export const ENUM_CONTRACT_STATUS = Object.freeze({
  UNDEFINED: 0, // 未定義
  UNCONTRACTED: 1, // 未成約
  CLOSING: 2, // 成約
  UNSUCCESSFUL: 3, // 不成約
});

/**
 * 買取金支払区分
 */
export const ENUM_PAYMENT_CLASSIFICATION = Object.freeze({
  UNDEFINED: 0, // 未定義
  CASH: 1, // 現金
  TRANSFER: 2, // 振り込み
  CASHANDTRANSFER: 3, // 現金＋振り込み
});

/**
 * 銀行振込状況
 */
export const ENUM_MASTER_TRANSFER_STATUS = Object.freeze({
  NOT_REQUESTED: 0, // 未以来
  REQUESTED: 1, // 依頼済み
  TRANSFERRED: 2, // 振込済み
});

/**
 * 査定商材種別のassessment_category_id
 */
export const ENUM_ASSESSMENT_CATEGORY = Object.freeze({
  NOT_SIMPLE: 1,
  SIMPLE: 2,
});

export const ENUM_MASTER_QUESTION_STATUS = Object.freeze({
  TODO: 1, // 未対応
  DOING: 2, // 対応中
  DONE: 3, // 完了
  ABSENCE: 4, // 不在
  ABSENCE_COMPLETED: 5, // 不在対応完了
});

// 専門査定依頼
export const ENUM_ASSESSMENT_CLASSIFICATION = Object.freeze({
  SELF: 1, // 自己査定
  EXPERT: 2, // 専門査定
});

// 本人限定郵便受取状況
export const ENUM_MASTER_REGISTERED_MAIL_STATUS = Object.freeze({
  UNNECESSARY: 1, // 不要
});

// 査定商材種別
export const ENUM_ASSESSMENT_ITEM_TYPE = Object.freeze({
  KIMONO: 1,
  OBI: 2,
  WASO_KOMONO: 3,
  TANMONO: 4,
  FUR: 5,
  ANTIQUE: 6,
  LIQUOR: 7,
  TELEPHONE_CARD: 8,
  VOUCHER: 9,
  OLD_COIN: 10,
  POSTAGE_STAMP: 11,
  REVENUE_STAMP: 12,
  WATCH: 13,
  PRECIOUS_METAL: 14,
  DIAMOND: 15,
  NON_BRAND_JEWELRY: 16,
  BRAND_JEWELRY: 17,
  BRAND_BAG_SMALL_ARTICLES: 18,
  BRAND_ACCESSORY: 19,
  BRAND_APPAREL: 20,
  CAMERA: 21,
  CAR_PARTS: 22,
  ETC: 23,
  SIMPLE: 24,
});

// 買取許可ステータス
export const ENUM_PURCHASE_PERMISSION_STATUS = Object.freeze({
  UNCONFIRMED: 0,
  ALLOWED: 1,
  NOT_ALLOWED: 2,
});

/**
 * CRM
 */

// 査定依頼種別（crm用）
export const ENUM_ASSESSMENT_OFFER_TYPE = Object.freeze({
  VISIT: 1, // 訪問
  BRING: 2, // 持込
  DELIVERY: 3, // 宅配
  SIMPLE: 4, // 簡易
  NONE: 5, // 査定依頼なし
  STORE: 6, // 店舗査定
  CASH: 7, // CASH査定
  TL_STORE: 8, // 店舗査定（TL）
});

// アポ成立状況
export const ENUM_APPOINTMENT_CONCLUSION_STATUS = Object.freeze({
  UNESTABLISH: 1, // 未依頼
  SUCCESS: 2, // アポ成立
  FAILURE: 3, // 終了(アポ不成立)
  FOLLOW_UP: 4, // 追客見込み
  HOLD: 5, // 保留(アポ保留)
  EXCLUSION: 6, // アポ除外
  CANCEL: 7, // キャンセル
});

// アポ除外理由
export const ENUM_MASTER_EXCLUSION_REASONS = Object.freeze({
  DELETED_BY_CC: 7, // cc削除
  IGNORE_WEB: 8, // web除外
});

// アンケート種別
export const ENUM_MASTER_SURVEY_TYPE = Object.freeze({
  VISIT: 1, // 訪問
  STORE: 2, // 店舗
  STORE_FOLLOW_CALL: 3, // 店舗フォローコール
});

// 再訪状況
export const ENUM_MASTER_CUSTOMER_REVISIT_STATUS = Object.freeze({
  ID_FOLLOW_UP: 1, // 追客
  ID_FIXED: 2, // 確定
});

// オンライン査定状況
export const ENUM_ONLINE_ASSESSMENT_STATUS = Object.freeze({
  SUCCESS: 1, // アポ成立
  FOLLOW_UP: 2, // 追客見込み
  CANCEL: 3, // キャンセル
  CHANGE: 4, // 査定方法変更
});

/**
 * FEで行うマスキングの種類
 */
export const ENUM_MASKING_TYPE = Object.freeze({
  DELETE: null,
  MASKED_STR: '## 個人情報削除済み ##',
});

// テリトリー区分
export const MASTER_TERRITORY_CLASSIFICATION = Object.freeze({
  HEAD_OFFICE: 1, // 本社・倉庫
  CENTER: 2, // センター
  SALES_OFFICE: 3, // 営業所
  CARAVAN: 4, // キャラバン
  STORE: 5, // 店舗
  EXIBITION: 6, // 催事
});

// 銀行マスタ
export const ENUM_BANK_ID = Object.freeze({
  OTHER: 9999, // 該当なし
});

// ファイル区分
export const FILE_TYPE = Object.freeze({
  CONTRACT: 1, // 契約系ファイル（契約書、関連書類等）
  ASSESSMENT_ITEM_IMAGE: 2, // 査定商材画像ファイル（撮影画像、書き込み画像等）
  EVENT_DOCUMENT: 3, // 案件関連書類ファイル（不招請非該当確認書類、査定終了確認書類）
});

// 案件関連書類区分
export const MASTER_EVENT_DOCUMENT_CLASSIFICATION = Object.freeze({
  UNINVITED_SOLICITATION: 1, // 不招請非該当確認書類
  ASSESSMENT_COMPLETED: 2, // 査定終了確認書類
});

// キャンペーンID
export const CAMPAIGN_ID = Object.freeze({
  // アンケート外部SaaS化により、画面上はキャンペーンIDを固定で設定する
  // ご来店のきっかけ設問にセットするID
  WALK_IN_STORE_SURVEY: 3170, // 店舗飛び込みアンケート
});

/**
 * FUND
 */
// 準備金種別
export const ENUM_MASTER_SALES_RESERVE_TYPE = Object.freeze({
  APPRAISER: 1, // 査定員口座
  RUM: 2, // RUM口座
  CORPORATION: 3, // 法人口座
  FINANCE: 4, // 経理口座
  ADJUSTMENT: 5, // 調整用
  STORE: 6, // 店舗口座
});

export const ENUM_IMAGE_FILE_TYPE = Object.freeze({
  CONTRACT: 1, // 契約系ファイル(契約書、関連書類等)
  ASSESSMENT_ITEM: 2, // 査定商材画像ファイル(撮影画像、書き込み画像等)
  EVENT_DOCUMENT: 3, // 案件関連書類ファイル（不招請非該当確認書類、査定終了確認書類))
  PROMAS_IMAGE: 4, // 商品マスタ画像ファイル
  PROMAS_PRIVATE_IMAGE: 5, // 商品マスタPrivate画像ファイル
  PROMAS_PRIVATE_IMAGE_CONDITION: 6, // 商品マスタPrivate状態画像ファイル
});
// redash
export const ENUM_REDASH_URL = Object.freeze({
  IS: 'https://redash.bi.buysell-technologies.io/public/dashboards/J61I9yHoWJMScRdfAE6uS1Z0XxNBlVIaSuDJxqBl?org_slug=default',
  FS: 'https://redash.bi.buysell-technologies.io/public/dashboards/F4M1Ctwid86qVf5tuaDwOBG7PJNRk1nLhWZDAWds?org_slug=default',
  MARKETING:
    'https://redash.bi.buysell-technologies.io/public/dashboards/J61I9yHoWJMScRdfAE6uS1Z0XxNBlVIaSuDJxqBl?org_slug=default',
  RUM: 'https://redash.bi.buysell-technologies.io/public/dashboards/IUxDgBdjsXAUiat9gWP2mu81WgQ2UcLaR1xGBKpH?org_slug=default',
  PRODUCT:
    'https://redash.bi.buysell-technologies.io/public/dashboards/HSnVj4YGCApRb8EVG2eyBUGBs0EdZBjVqqXkIOlb?org_slug=default',
  DELIVERY:
    'https://redash.bi.buysell-technologies.io/public/dashboards/HSnVj4YGCApRb8EVG2eyBUGBs0EdZBjVqqXkIOlb?org_slug=default',
  ENABLEMENT:
    'https://redash.bi.buysell-technologies.io/public/dashboards/F4M1Ctwid86qVf5tuaDwOBG7PJNRk1nLhWZDAWds?org_slug=default',
});

// TL redash
export const ENUM_TL_REDASH_URL = Object.freeze({
  SALE_SPECIALEVENT:
    'https://redash.bi.buysell-technologies.io/public/dashboards/VEJhvZsmhUXBXYD3J2GrThlT1etKtig2oqggTAJZ?org_slug=default',
  STORE_MANAGER:
    'https://redash.bi.buysell-technologies.io/public/dashboards/DS0fWvGbTJhmLdVXJcB7hX19uyTdWWx3ML7JwtSq?org_slug=default&p_%E8%A9%B2%E5%BD%93%E6%9C%88=d_now&p_%E8%A9%B2%E5%BD%93%E6%97%A5=d_now',
  STORE:
    'https://redash.bi.buysell-technologies.io/public/dashboards/DS0fWvGbTJhmLdVXJcB7hX19uyTdWWx3ML7JwtSq?org_slug=default&p_%E8%A9%B2%E5%BD%93%E6%9C%88=d_now&p_%E8%A9%B2%E5%BD%93%E6%97%A5=d_now',
  SUBSIDIARY_STORE:
    'https://redash.bi.buysell-technologies.io/public/dashboards/DS0fWvGbTJhmLdVXJcB7hX19uyTdWWx3ML7JwtSq?org_slug=default&p_%E8%A9%B2%E5%BD%93%E6%9C%88=d_now&p_%E8%A9%B2%E5%BD%93%E6%97%A5=d_now',
  POWER_USER:
    'https://redash.bi.buysell-technologies.io/public/dashboards/DS0fWvGbTJhmLdVXJcB7hX19uyTdWWx3ML7JwtSq?org_slug=default&p_%E8%A9%B2%E5%BD%93%E6%9C%88=d_now&p_%E8%A9%B2%E5%BD%93%E6%97%A5=d_now',
  STORE_RU:
    'https://redash.bi.buysell-technologies.io/public/dashboards/DS0fWvGbTJhmLdVXJcB7hX19uyTdWWx3ML7JwtSq?org_slug=default&p_%E8%A9%B2%E5%BD%93%E6%9C%88=d_now&p_%E8%A9%B2%E5%BD%93%E6%97%A5=d_now',
});

export const ENUM_PRIVACY_POLICY_URL = Object.freeze({
  BUYSELL: 'https://buysell-technologies.com/privacy/',
  TIMELESS: 'https://timeless-co.com/privacypolicy_store/',
});

/**
 * PROMAS
 */
export const PRM_MASTER_COMPETITOR = Object.freeze({
  ECORING: 1,
});

/**
 * Reservation
 */
// 非稼働予定の色
export const ENUM_RESERVE_COLOR = Object.freeze({
  yellow: { id: 1, label: '黄', value: 'yellow', selectable: true, class: '' },
  green: {
    id: 2,
    label: '緑',
    value: 'green',
    selectable: true,
    class: 'event_green',
  },
  red: {
    id: 3,
    label: '赤',
    value: 'red',
    selectable: true,
    class: 'event_red',
  },
  gray: {
    id: 4,
    label: 'グレー',
    value: 'gray',
    selectable: true,
    class: 'event_gray',
  },
  blue: {
    id: 5,
    label: '青',
    value: 'blue',
    selectable: false,
    class: 'event_blue',
  },
});

export const INVOICE_SYSTEM_CLASSIFICATION = {
  unidentified: 1,
  yes: 2,
  no: 3,
  unknown: 4,
};

export const ENUM_CONTACT_CLASSIFICATION = Object.freeze({
  MY_CELL_PHONE: 1, // 自宅電話
  MY_HOME_PHONE: 2, // 携帯電話
});

export const ENUM_ADDRESS_CLASSIFICATION = Object.freeze({
  HOME: 1, // 自宅
  PARENTS_HOME: 2, // 実家
});
